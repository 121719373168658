import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentcontainer"
import PortfolioSubSiteContent from "../../components/portfoliosubsitecontent"


export default function Newborn({ data }) {

  return (
    <div>
      <Layout siteTitle={data.content.frontmatter.title}>
        <SEO
          title="Neugeborenen Shooting – Natürliche Babyfotos in Köln"
          description="Natürliche und liebevolle Neugeborenenfotos entspannt bei euch zuhause. Babyfotografin Köln und Umgebung. Reserviert jetzt euren Termin. Viele Bilder, Preise und Infos."
        />
        <ContentContainer>
          <PortfolioSubSiteContent imagestop={data.imagestop} imagesbottom={data.imagesbottom} content={data.content} />
        </ContentContainer>
      </Layout>
    </div>
  )
}

/*
  Diese Funktion holt sich mit GraphQL
  (1) den Markdown wird gefiltert nach dem Slug-Tag in der Datei
  in "html" ist dann das geparste Markdown als HTML String zu finden
  Übergeben werden die Daten an die Seitenfunktion über "data".
  Der Inhalt von Data lässt sich dann über {content.data.xxx} verwenden.
  Der Bezeichner content lässt sich frei wählen, um unterschiedliche Abfrageergebnisse auseinander zu halten
  Der HTML-String lässt sich über dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} injezieren
  (2) Die Bilder, die auf der Seite gebraucht werden (mit gewähltem Bezeichner Images)
*/
export const pageQuery = graphql`
  query {
    content: markdownRemark(frontmatter: {slug: {eq: "/content/portfolio/newborn"}}) {
      frontmatter {
        date
        slug
        title
      }
      html
      fileAbsolutePath
    }
    imagestop: allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "images/portfolio/newborn/top"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          id
          childrenImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imagesbottom: allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "images/portfolio/newborn/bottom"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          id
          childrenImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`